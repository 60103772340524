// StatusButton.tsx
import React, { FC } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface ShipmentStatusButtonProps {
  syncStatus: string;
  syncMessage?: string;

}

const ShipmentStatusButton: FC<ShipmentStatusButtonProps> = ({ syncStatus, syncMessage}) => {
    const renderTooltip = (status: string) => {
      let message = syncMessage || 'No sync message available';
      if (status === 'ERROR') {
        message = 'Cannot sync unlike line items.';
      }
      return <Tooltip id="status-tooltip">{message}</Tooltip>;
    };

  let buttonContent;

  switch (syncStatus) {
    case 'ERROR':
      buttonContent = 'Failed';
      break;
    case 'PENDING':
      buttonContent = 'Pending';
      break;
    case 'SHIPPED':
      buttonContent = 'Shipped';
      break;
    default:
      buttonContent = 'NA'; // Default content for unknown statuses
      break;
  }


  return (
    <>
     <OverlayTrigger
              placement="top"
              overlay={renderTooltip(syncStatus)}
            >
      <Button
        style={{
          backgroundColor:
            syncStatus === 'ERROR' ? 'red' :
            syncStatus === 'PENDING' ? '#FFD700' :
            syncStatus === 'SHIPPED' ? 'green' :
            'darkslategray', // Default color for unknown statuses
          color: 'white',
          padding: '5px 10px',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer',
          width:"130px"
        }}
      >
         {buttonContent}
      </Button>
      </OverlayTrigger>
    </>
  );
};

export default ShipmentStatusButton;
