import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import { getAvailablePlatformAsync } from '../actions/platformSettingActions'
import { INITIAL_STATE_FOR_PLATFORM_SETTING_SLICE } from 'src/interfaces/Platform.interface'



const initialState:INITIAL_STATE_FOR_PLATFORM_SETTING_SLICE = {
  loadPlatformSetting: false,
  settings: null,
}

const fetchAvailablePlatformSlice = createSlice({
  name: 'availablePlatformSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getAvailablePlatformAsync.pending), (state, action) => {
      state.loadPlatformSetting = true
    })
    builder.addMatcher(isAnyOf(getAvailablePlatformAsync.rejected), (state, action) => {
      state.loadPlatformSetting = false
    })
    builder.addMatcher(isAnyOf(getAvailablePlatformAsync.fulfilled), (state, action) => {
      state.settings = action.payload?.data
      state.loadPlatformSetting = false
    })
  },
  reducers: {},
})

export const {} = fetchAvailablePlatformSlice.actions
export default fetchAvailablePlatformSlice.reducer