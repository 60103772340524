import { FC, Suspense, useEffect, useState} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../marketConnect/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../marketConnect/assets/ts/_utils'
import {toAbsoluteUrl, WithChildren} from '../../marketConnect/helpers'
import ConnectedPlatforms from '../pages/listing/ConnectedPlatforms'
import Platforms from 'src/app/pages/listing/Platforms'
import TemplateAndPricing from 'src/app/pages/template-and-pricing/TemplateAndPricing'
import AllProducts from '../pages/products/AllProducts'
import Orders from '../pages/orders/Orders'
import Inventory from '../pages/inventory/Inventory'
import ProductsTable from '../pages/listing/components/listing-products/ProductsTable'
import OrderDetails from '../pages/orders/OrderDetails'
import AccountSetting from '../pages/AccountSetting'
import ProductDetail from '../pages/product-detail/ProductDetail'
import UserInvites from '../pages/user-invite/UserInvites'
import CreateProduct from '../pages/create-product/CreateProduct'
import UpdateProduct from '../pages/update-Product/UpdateProduct'
import ProductVariantDetail from '../pages/product-detail/productVariantDetail'
import ManageCustomers from '../pages/admin-panel/ManageCustomers'
import Report from '../pages/admin-panel/Report'
import ManageCustomerscopy from '../pages/admin-panel/ManageCustomerscopy'
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'
import { SuperAdminRoutes } from './SuperAdminRoutes'
import { fetchUserAccountDetailsAsync } from 'src/redux/actions/accountActions'
import { accessSessionTokenKeyName, loggedInAdminAccessToken } from 'src/utils/constants'
import CSVDownload from '../pages/download/CSVDownload'
import PlatformSetting from '../pages/listing/PlatformSetting/PlatformSetting'
import PrimaryPlatformSetting from '../pages/listing/PlatformSetting/PrimaryPlatformSetting'
import Vendors from '../pages/vendor/Vendors'
import AddUpdateVendorData from '../pages/vendor/AddUpdateVendorData'
import DynamicPlatformSetting from '../pages/listing/DynamicPlatformSetting'
import Pricing from '../pages/pricing-page/Pricing'
import Dashboard from '../pages/dashboard/Dashboard'
import FeatureRequest from '../pages/feature-request/FeatureRequest'
import Invoices from '../pages/invoice/Invoices'
import TourPopup from '../pages/dashboard-tour/TourPopup'
import BillingPlanDetail from '../pages/BillingPlanDetail'
import OrderAcknowledgements from '../pages/orders/OrderAcknowledgements'
import { getUserPlanAsync } from 'src/redux/actions/pricingActions'
import { fetchConnectedPlatformAsync, fetchPlatformsAsync } from 'src/redux/actions/platformActions'
import CancellationLogs from '../pages/orders/CancellationLogs'
import clsx from 'clsx'
import { HeaderUserMenu } from 'src/marketConnect/partials'
import RefundLogs from '../pages/orders/RefundLogs'
import InvoiceLogs from '../pages/orders/InvoiceLogs'
import Onboard from '../pages/new-onboard-process/Onboard'
import UpdatedPricingPage from '../pages/pricing-page/UpdatedPricingPage'
import UpdatedPricingPageV2 from '../pages/pricing-page/UpdatedPricingPageV2'
import AvailablePlatformSetting from '../pages/listing/AvailablePlatformSetting'
import TourPopupUpdated from '../pages/dashboard-tour/TourPopupUpdated'


// import CreateProduct from '../pages/create-product/createProduct' 


const PrivateRoutes = () => {

  const {  fetchedUserAccountData } = useAppSelector(state=>state.userAccountData)
  const { visible } = useAppSelector(state=>state.tourPopup)
  const {  isLoggedIn, userDetail } = useAppSelector(state=>state.auth)
  const [loading, setLoading] = useState(true)
   const userAvatarClass = 'symbol-35px'
  const itemClass = 'ms-1 ms-md-4'
  const dispatch = useAppDispatch()

  useEffect(() => {
    // Dispatch the fetch user data action on component mount
    if(isLoggedIn){
      setLoading(true)
      Promise.all([
        dispatch(fetchUserAccountDetailsAsync()),
        // dispatch(getUserPlanAsync()),
      ])
      .finally(()=>{
        dispatch(fetchPlatformsAsync())
        dispatch(fetchConnectedPlatformAsync())
        setLoading(false)
        
      })
    }else{
      dispatch(fetchUserAccountDetailsAsync()).then().catch()
    }
    // dispatch(fetchUserAccountDetailsAsync())
    //   .then( async (response) => {
    //     console.log('User account data: in prr', response.payload)
    //     await dispatch(getUserPlanAsync())
    //    setLoading(false)
    //   })
    //   .catch((error) => {
    //     console.error('Error fetching user data: in prr', error)
    //     setLoading(false)
    //   })
  }, [ isLoggedIn, localStorage.getItem(accessSessionTokenKeyName)])

  
  if(loading ) return <Loader/>
  
  return (
    <>
    {
      fetchedUserAccountData?.user_type === 'USER' && !fetchedUserAccountData.isOnboardingProcessCompleted && !localStorage.getItem(loggedInAdminAccessToken) ?
      <>
      <div style={{position:'fixed', top:'2.6%', right:'0%', zIndex:9999, display:'flex', cursor:'pointer', paddingRight:'30px' }} >
            <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol border border-2 rounded-circle p-2', userAvatarClass, visible ? 'bg-secondary' : ''  )}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={toAbsoluteUrl('/media/avatars/man_5-1024.webp')} alt='' />
        </div>
        <HeaderUserMenu />
      </div>
      </div>
      <TourPopup/> 
      </>
      : null
    }
    <Routes>
       { fetchedUserAccountData?.user_type === 'USER' || fetchedUserAccountData===null  ? 
         <Route element={<MasterLayout />}>
              {
                fetchedUserAccountData?.id ? 
                 fetchedUserAccountData?.is_subscribed && fetchedUserAccountData.isOnboardingProcessCompleted ? 
                  <Route path='/*' element={<Navigate to='/dashboard' />} /> : 
                  <Route path='/*' element={<Navigate to='/pricing' />} />
                : <Route path='/*' element={<Navigate to='/dashboard' />} />
              }
              { fetchedUserAccountData?.id ?
                fetchedUserAccountData?.is_subscribed && fetchedUserAccountData.isOnboardingProcessCompleted ?
                  <Route path='auth/*' element={<Navigate to='/dashboard' />} /> : 
                  <Route path='auth/*' element={<Navigate to='/pricing' />} />
                : <Route path='auth/*' element={<Navigate to='/dashboard' />} />
              }
              
              <Route path='dashboard' element={<DashboardWrapper />} />
              <Route path='test-dashboard' element={<Dashboard />} />

              <Route path='/platforms' element={<Platforms />} />
              <Route path='/listing-channels' element={<ConnectedPlatforms />} />
              <Route path='/listing-channels/:selectedPlatformId' element={<ConnectedPlatforms />} />
              <Route path='/platform-setting/:selectedPlatformId' element={<PlatformSetting/>}/>
              <Route path='/primary-platform-setting/:selectedPlatformId' element={<PrimaryPlatformSetting/>}/>
              <Route path='/template-and-pricing' element={<TemplateAndPricing />} />
              <Route path='/products-all' element={<AllProducts/>}  />
              <Route path='/orders-all' element={<Orders/>}  />
              <Route path='/inventory-all' element={<Inventory/>}  />
              <Route path='/listing-product-table/:platformId/:id' element={<ProductsTable/>} />
              <Route path='/sales/details/:orderId' element={<OrderDetails/>} />
              <Route path='/product-variant-detail/:variantId/platform/:platformId/product/:productId/:availablePlatformId' element={<ProductDetail/>} />
              <Route path='/product-linked-variant-detail/:variantId/platform/:platformId/product/:productVariantId' element={<ProductVariantDetail/>} />
              <Route path='/account/settings' element={<AccountSetting/>} />
              <Route path='/user-invite' element={<UserInvites/>}/>
              <Route path='/create-product' element={<CreateProduct/>}/>
              <Route path='/update-product' element={<UpdateProduct/>}/>
              <Route path='/manage-customers' element={<ManageCustomers/>}/>
              <Route path='/manage-customers-copy' element={<ManageCustomerscopy/>}/>
              <Route path='/admin-reports' element={<Report/>}/>
              <Route path='/products-files' element={<CSVDownload/>}/>
              <Route path='/vendors' element={<Vendors/>}/>
              <Route path='/edit-vendor/:vendorId' element={<AddUpdateVendorData  />}/>
              <Route path='/platform-setting-new/:availablePlatformId/:selectedPlatformId' element={<DynamicPlatformSetting/>} />
              <Route path='/pricing' element={<UpdatedPricingPageV2/>}/>
              <Route path='/pricing/:platformId' element={<UpdatedPricingPageV2/>}/>
              <Route path='/feature-request' element={<FeatureRequest/>}/>
              <Route path='/invoice-all' element={<InvoiceLogs/>}/>
              <Route path='/billing-detail' element={<BillingPlanDetail activePlanDetail={null}/>}/>
              <Route path='/order-acknowledges' element={<OrderAcknowledgements/>}/>
              <Route path='/order-cancellation' element={<CancellationLogs/>}/>
              <Route path='/order-refund' element={<RefundLogs/>}/>
              <Route path='/new-onboard' element={<Onboard />}/>
              <Route path='/available-platform-setting/:availablePlatformId' element={<AvailablePlatformSetting />}/>






              {/* Page Not Found */}
              <Route path='*' element={<Navigate to='/error/404' />} />
          </Route> :
          <Route element={<MasterLayout />}>
            <Route path='/*' element={<SuperAdminRoutes/>} />
          </Route>         
      }
    </Routes>
    </>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}