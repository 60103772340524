import { createAsyncThunk } from "@reduxjs/toolkit"
import AxiosClient from "../AxiosClient"


export const getSettingsOfSelectedPlatformAsync = createAsyncThunk(
    'platformSettings/getSettingsOfSelectedPlatformAsync',
    async function (params:{availablePlatformId:string}, toolkit) {
      return await AxiosClient('GET', `/available-platform/${params.availablePlatformId}/settings`, [], toolkit, [] )
    }
  )

  export const getSettingsOfPrimaryPlatformAsync = createAsyncThunk(
    'platformSettings/getSettingsOfPrimaryPlatformAsync',
    async function (params:{primaryAvailablePlatformId:number}, toolkit) {
      return await AxiosClient('GET', `/available-platform/${params.primaryAvailablePlatformId}/settings?type=MAIN_STORE_SETTING`, [], toolkit, [] )
    }
  )


  //api use in dynamic setting for get saved field mapping by their id
  export const getSavedFieldMappingsDataAsyncByMappingId = createAsyncThunk(
    'platformSettings/getSavedFieldMappingsDataAsyncByMappingId',
    async function (params:{mappingId:number}, toolkit) {
      return await AxiosClient('GET', `/api/field-mapping-with-setting/${params.mappingId}`, [], toolkit, [] )
    }
  )

  /**
   * @description saveFieldMappingForSectionWiseAsync is used for "save" and "update" both
   */
  export const saveFieldMappingForSectionWiseAsync = createAsyncThunk(
    'platformSettings/saveFieldMappingForSectionWiseAsync',
    async function (params:{mappingId:number|null, payload:any}, toolkit) {
      if(params.mappingId){
        return await AxiosClient('POST', `/api/field-mapping-with-setting/${params.mappingId}`, params.payload, toolkit, [] )
      }else{
        return await AxiosClient('POST', `/api/field-mapping-with-setting`, params.payload, toolkit, [] )
      }
    }
  )

  export const getAvailablePlatformAsync = createAsyncThunk(
    'platformSettings/getAvailablePlatformAsync',
    async function (params:{selectedAvailablePlatformId:string}, toolkit) {
      return await AxiosClient('GET', `/available-platform/${params.selectedAvailablePlatformId}`, [], toolkit, [] )
    }
  )