import moment from 'moment'
import React, {FC, useEffect, useState} from 'react'
import DataTable, {TableColumn} from 'react-data-table-component'
import useAppToast from 'src/hooks/useAppToast'
import AppDatePickerRange from '../orders/components/AppDatePickerRange'
import {Spinner} from 'react-bootstrap'
import StatusButton from '../orders/StatusButton'
import {KTIcon, KTSVG} from 'src/marketConnect/helpers'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {
  deleteUserAsync,
  getCustomersAsync,
  loginAsCustomerAsync,
  updateCustomerStatusAsync,
} from 'src/redux/actions/customerAction'
import {CUSTOMER} from 'src/app/_interfaces/customer.interface'
import CommonAlertModal from 'src/utils/modals/CommonAlertModal'
import {
  accessSessionTokenKeyName,
  loggedInAdminAccessToken,
  loggedInAdminRefreshToken,
  refreshSessionTokenKeyName,
} from 'src/utils/constants'
import {useNavigate} from 'react-router-dom'
import {setLoggingData} from 'src/redux/slices/authSlice'
import {fetchUserAccountDetailsAsync} from 'src/redux/actions/accountActions'
import {messageTemplete} from 'src/utils/messageTemplete'
import {getCustomPlansAsync} from 'src/redux/actions/pricingActions'
import {CustomPlanData} from 'src/interfaces/Platform.interface'
import { deleteCustomPlanAsync } from 'src/redux/actions/passwordAction'

const CommonColumn: FC<{rowData: string, rowId?: number}> = ({rowData,rowId}) => {
  const [isDeleteCustomPlan, setIsDeleteCustomPlan] = useState(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const dispatch = useAppDispatch();
  const [selectedPlanId, setSelectedPlanId] = useState<number | null>(null);

  const handleDeleteCustomPlan = (id:number) => {
    setSelectedPlanId(id);
    setIsDeleteCustomPlan(true)
  };

  return (
    <div className='row'>
      {rowData === "Delete"&& rowId ? (
        <button
          className='btn btn-sm btn-danger  m-2'
          onClick={() =>{handleDeleteCustomPlan(rowId)}}
        >
          Delete
        </button>
      ) : (
      <p className='d-inline-block  ' style={{maxWidth: '180px'}}>
        {rowData}
      </p>)}
      <CommonAlertModal
        key={'Delete Custom Plan Confirmation'}
        title={'Are you sure you want to delete this custom plan?'}
        subtitle=''
        isTermsVisible={false}
        successDisabled={isDeleting}
        successBtnTitle={isDeleting? "Deleting...": 'Delete'}
        cancelBtnTitle={'Cancel'}
        showCloseButton={true}
        modalClassName='custmd'
        isVisible={isDeleteCustomPlan}
        onCancel={() => {
          setIsDeleteCustomPlan(false);
          setSelectedPlanId(null);
        }}
        onSuccess={() => {
          setIsDeleting(true)
          if (selectedPlanId) {
            setIsDeleting(true);
            dispatch(deleteCustomPlanAsync({customPlanDataId: selectedPlanId}))
              .unwrap()
              .then(() => {
                // Success handling
                setIsDeleting(false);
                setIsDeleteCustomPlan(false);
                setSelectedPlanId(null);
              })
              .catch((error) => {
                // Error handling
                console.error(error);
                setIsDeleting(false);
              });
          }
        }}
      />
    </div>
  )
}

const PricingPlans: FC = () => {
  const [searchString, setSearchString] = useState('')
  const {successToast, errorToast, warningToast} = useAppToast()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {customPlanListForUsers, customPlansListLoader, totalCustomPlans} = useAppSelector(
    (state) => state.pricing
  )

  //------
  const [selectedUserData, setSelectedUserData] = useState<null | CUSTOMER>(null)
  const [paginationData, setPaginationData] = useState({
    page_limit: 25,
    page_num: 1,
    search: '',
  })

  const columns: TableColumn<CustomPlanData>[] = [
    {
      name: 'Customer',
      cell: (row) => (row ? <CommonColumn rowData={row?.user?.name ?? ''} /> : null),
      minWidth: '140px',
    },

    {
      name: 'Email',
      selector: (row) => row?.user?.email,
      cell: (row) => (row ? <CommonColumn rowData={row?.user?.email} /> : null),
      minWidth: '200px',
    },

    // {
    //   name: 'Plan Status',
    //   selector: (row) => row.status,
    //   cell: (row) => {
    //     if (row.status) {
    //       return <span className=''>Active</span>
    //     } else {
    //       return <span className=''>Inactive</span>
    //     }
    //   },
    //   center: true,
    //   minWidth: '120px',
    // },

    // {
    //   name: 'Current Plan',
    //   cell: (row) => (
    //     <div>
    //       <small className=''>{row?.user_plan_name}</small>
    //       <br />
    //       <small>
    //         {row?.user_plan_price && row?.user_plan_price_currency
    //           ? row?.user_plan_price + ' ' + row?.user_plan_price_currency
    //           : '0'}
    //         / <span style={{fontSize: '9px'}}>{' Monthly'}</span>
    //       </small>
    //     </div>
    //   ),
    //   minWidth: '140px',
    // },
    {
      name: 'Plan',
      cell: (row) => (
        <div>
          <p className='mb-0'>$ {row.price.value} / <span>{ row?.billing_frequency==='ANNUAL'  ? `Annually` : 'Monthly' }</span> </p>
        </div>
      ),
      minWidth: '100px',
    },
    {
      name: 'Total SKUs ',
      selector: (row) => row?.product_limit,
      cell: (row) => (row ? <CommonColumn rowData={row?.product_limit+''} /> : null),
      center:true,
      minWidth: "140px"
    },
    {
      name: 'Total Orders',
      selector: (row) => row?.order_limit,
      cell: (row) => (row ? <CommonColumn rowData={row?.order_limit+''} /> : null),
      center:true,
      minWidth: "150px"
    },

    {
      name: 'Billed Manually',
      selector: (row) => row?.description,
      cell: (row) => (row ? <CommonColumn rowData={row?.description==="BILLED_MANUALLY"?"Yes": "No"} /> : null),
      center:true,
      minWidth: "140px"
    },

    {
      name: 'Created Date',
      cell: (row) =>
        row.created_at ? moment.utc(row?.created_at).format('hh:mm A, DD-MMM-YYYY') : null,
      sortable: false,
      minWidth: '170px',
    },
    {
      name: 'Updated At',
      cell: (row) =>
        row?.updated_at ? moment.utc(row?.updated_at).format('hh:mm A, DD-MM-YYYY') : null,
      minWidth: '170px',
    },

    {
      name: 'Action',
      selector: (row) => row?.id,
      cell: (row) => (row ? <CommonColumn rowData={"Delete"} rowId={row.id} /> : null),
      center:true,
      minWidth: "140px"
    },
    // {
    //   name: 'Enable Product Module',
    //   cell: (row) => (
    //     <div>
    //       <EnableProductModule isEnableProductModule={row.isEnableProductModule} customer={row} />
    //     </div>
    //   ),

    //   minWidth: '200px',
    // },
    // {
    //   name: 'Action',
    //   cell: (row) => (
    //     <UserActionsCell
    //       customerDetail={row}
    //       onDeleteCustomer={()=>{
    //         setSelectedUserData(row)
    //         setConfirmDeleteUser(true)
    //       }}
    //       onLoginAsCustomer={() => {
    //         setConfirmLoginAsUser(true)
    //         setSelectedUserData(row)
    //       }}
    //       updateCustomerStatus={() => {
    //         setConfirmUpdateStatus(true)
    //         setSelectedUserData(row)
    //       }}
    //     />
    //   ),
    //   center: true,
    //   minWidth: '140px',
    // },
  ]

  useEffect(() => {
    dispatch(getCustomPlansAsync({...paginationData}))
  }, [paginationData])

  return (
    <div>
      <>
        <div className='mb-3'>
          <span className='fw-bold text-lg ' style={{fontSize: '16px'}}>
            Custom Pricing Plans
          </span>
        </div>

        <div className='card border-0 shadow rounded '>
          <div className=' bg-white' style={{minHeight:'10px'}}>
            <div className='card-title' >
              {/* <input
                className='form-control input-sm '
                type='search'
                placeholder='Search By Name or Email'
                aria-label='Search'
                onChange={(ev) => {
                  setSearchString(ev.target.value)
                }}
                onKeyDown={(ev) => {
                  if (ev.key == 'Enter') {
                    setPaginationData((pre) => {
                      return {...pre, search: searchString, page: 1}
                    })
                  }
                }}
                value={searchString}
                style={{minWidth: '180px'}}
              /> */}
            </div>
            <div className='card-toolbar'>
              <div className='d-flex justify-content-end mb-4 mt-3 me-3'>
                <div className=''>
                  {/* <div className='d-flex align-items-center'>
                    <button
                      className='form-control rounded input me-2'
                      style={{height: '100%', padding: '0.5rem 1rem'}}
                      onClick={() => setShowDateRangePicker(!showDateRangePicker)}
                    >
                      {paginationData.from_date && paginationData.to_date
                        ? `${moment(paginationData.from_date).format('YYYY/MM/DD')}  -  ${moment(
                            paginationData.to_date
                          ).format('YYYY/MM/DD')}`
                        : 'Filter Customer By Date'}
                    </button>
                  </div> */}

                  {/* <AppDatePickerRange
                    isVisible={showDateRangePicker}
                    handleClose={() => setShowDateRangePicker(false)}
                    onPickRange={({startDate, endDate}) => {
                      setPaginationData((pre) => ({
                        ...pre,
                        from_date: moment(startDate).format('YYYY-MM-DD'),
                        to_date: moment(endDate).format('YYYY-MM-DD'),
                        page_num: 1,
                      }))
                    }}
                  /> */}
                </div>

                {/* <div className='ms-2'>
                  <button
                    type='button'
                    className='btn fs-8 btn-sm btn-light-warning fw-bold'
                    onClick={handleResetFilters}
                  >
                    Reset Filters
                  </button>
                </div> */}
                <div className='ms-2'>
                  <button
                    type='button'
                    className='btn fs-8 btn-sm btn-primary fw-bold'
                    onClick={() => {
                      navigate('/manage-pricing')
                    }}
                  >
                    <i className='bi bi-plus-lg text-white'></i> Add New
                  </button>
                </div>
              </div>
            </div>
          </div>
          <DataTable
            fixedHeader={true}
            key={paginationData.page_limit + ''}
            data={customPlanListForUsers}
            columns={columns}
            persistTableHead
            paginationTotalRows={totalCustomPlans}
            responsive
            pagination
            paginationServer
            progressPending={customPlansListLoader}
            paginationPerPage={paginationData.page_limit}
            paginationResetDefaultPage
            progressComponent={
              <div className='d-flex py-10 justify-content-center items-center'>
                {' '}
                <Spinner animation='border' />{' '}
              </div>
            }
            onChangePage={(pageNumber, totalRows) => {
              setPaginationData((pre) => {
                return {...pre, page_num: pageNumber}
              })
            }}
            onChangeRowsPerPage={(currentPage) => {
              setPaginationData((pre) => {
                return {...pre, page_limit: currentPage}
              })
            }}
            customStyles={{
              table: {
                style: {
                  height: '54vh',
                },
              },
              headCells: {
                style: {fontWeight: 'bold', fontSize: '14px'},
              },
              cells: {
                style: {},
              },
            }}
          />
        </div>
      </>
    </div>
  )
}

export default PricingPlans
