import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {getOrderDetailsAsync} from 'src/redux/actions/orderActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'

import OrderSummary1 from './components/OrderSummary1'
import Loader from 'src/utils/Loader'

const OrderDetails = () => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const {loadingOrderDetails, orderDetails} = useAppSelector((state) => state.orderDetailsData)

  const {orderId} = useParams()

  useEffect(() => {
    // Call the getOrderDetailsFromId function
    if(orderId){
      setLoading(true)
      dispatch(getOrderDetailsAsync(orderId))
        .then(() => {
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }, [orderId])

  // ... rest of your component code

   console.log('loading', loading)

  return (
    <div>
      <div
              id='kt_page_title'
              data-kt-swapper='true'
              data-kt-swapper-mode='prepend'
              data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
              className='page-title d-flex flex-wrap me-3 flex-column justify-content-center mb-4'
            >
              <h1 className='page-heading d-flex text-gray-900 fw-bold fs-3 my-0 flex-column justify-content-center'>
                Order Detail
              </h1>
              <ul className='breadcrumb text-muted  fw-semibold fs-6 my-0 mb-2 mr-5 ml-5'>
                              <li className='breadcrumb-item'>
                                <Link
                                  to={`/orders-all`} 
                                  className='text-primary text-decoration-none'
                                >
                                  Sales Order Logs
                                </Link>
                              </li> 
                              <li className='breadcrumb-item'> Order Details</li>
                          </ul>
            </div>
      <div className='separator my-4'></div>
     { loading ? <Loader/>
      :
      <OrderSummary1 />
     }
    </div>
  )
}

export default OrderDetails
