/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {fetchConnectedPlatformAsync, fetchPlatformsAsync} from 'src/redux/actions/platformActions'
import {SidebarMenuItem2} from './SidebarMenuItem2'
import {accessSessionTokenKeyName} from 'src/utils/constants'
import { setTheRangeId } from 'src/redux/slices/productSlice'
import { toAbsoluteUrl } from 'src/marketConnect/helpers'
import SidebarMenuItemForExternalLink from './SidebarMenuItemForExternalLinks'
import { PlatformDataType } from 'src/interfaces/Platform.interface'

const SidebarMenuMain = () => {
  const {fetchedConnectedPlatformData} = useAppSelector((state) => state.fetchConnectedPlatform)
  const { fetchedUserAccountData } = useAppSelector(state=>state.userAccountData)
  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)
  // console.log('login data', fetchedConnectedPlatformData)

  const [primaryStorePlatforms, setPrimaryStorePlatforms] = useState([])
  const [secondaryStorePlatforms, setSecondaryStorePlatforms] = useState([])
  const intl = useIntl()
  const dispatch = useAppDispatch()
  // let tokenNew = sessionStorage.getItem(`access_token`)

  useEffect(() => {
    if (localStorage.getItem(accessSessionTokenKeyName)) {
      //for available platform
      // dispatch(fetchPlatformsAsync())

      //for platform
      // dispatch(fetchConnectedPlatformAsync())
    }
  }, [localStorage.getItem(accessSessionTokenKeyName)])

  useEffect(() => {
    if (fetchedConnectedPlatformData && fetchedConnectedPlatformData.length > 0) {
      const primaryStore: any = fetchedConnectedPlatformData.filter(
        (platform) => platform.isMainStore
      )
      const secondaryStore: any = fetchedConnectedPlatformData.filter(
        (platform) => !platform.isMainStore
      )

      setPrimaryStorePlatforms(primaryStore)
      setSecondaryStorePlatforms(secondaryStore)
    }
  }, [fetchedConnectedPlatformData])
  // console.log('setPrimaryStorePlatforms  =', primaryStorePlatforms)
  // console.log('setSecondaryStorePlatforms  =', secondaryStorePlatforms)
console.log("fetchedUserAccountData?.id",fetchedUserAccountData?.id)
  return (
    <>

      {
        fetchedUserAccountData?.user_type==='USER' ?

        <>
        <SidebarMenuItem
            badge={ process.env['REACT_APP_ENV'] == 'production' ? true: false }
            to='/dashboard'
            icon='element-11'
            title={'Dashboard'}
          />

        { fetchedUserAccountData?.isEnableProductModule ? 

            <SidebarMenuItemWithSub title='Products' icon='abstract-16' to='#'>
              {/* Primary Store Products */}

              { primaryStorePlatforms.length ? primaryStorePlatforms.map((channel: PlatformDataType) => (
                <SidebarMenuItem2
                  to={`/listing-product-table/${channel.platform_info?.id}/${channel.id}`}
                  key={channel.id}
                  icon='abstract-16'
                  title={`Primary Store Products`}
                  imgSrc={channel?.platform_info?.logo_ref}
                />
              )) : null }

              {/* Secondary Store Products */}

              { secondaryStorePlatforms.length ? secondaryStorePlatforms.map((channel: PlatformDataType) => (
                <SidebarMenuItem2
                  to={`/listing-product-table/${channel.platform_info?.id}/${channel.id}`}
                  key={channel.id}
                  icon='abstract-16'
                  title={channel.display_name+" Products"}
                  imgSrc={channel?.platform_info?.logo_ref}
                />
              )) : null }


              {secondaryStorePlatforms.map((platform: any) => {

                if(platform.name==='THERANGEPLUS' && process.env.REACT_APP_ENV === 'staging' ){
                  dispatch(setTheRangeId(platform.id))
                  return(
                      <SidebarMenuItem2
                        to={`/products-files`}
                        key={'csvdn'+ platform.id}
                        title={'CSV Download'}
                        hasBullet
                      />
                    )
                }
              })}

              <SidebarMenuItem
                badge={true}
                to='/template-and-pricing'
                icon='abstract-36'
                title={'Listing Rules & Plans'}
              />
              {/* {process.env.REACT_APP_ENV === 'staging' && (
              )} */}
          
            </SidebarMenuItemWithSub>
          : null
        }

        <SidebarMenuItemWithSub title='Sales' icon='handcart' to='/orders-all'>
          <SidebarMenuItem
            to='/orders-all'
            title={intl.formatMessage({id: 'MENU.ORDERS'})}
            hasBullet
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/listing-channels' icon='abstract-36' title='Channels'>
          <SidebarMenuItem
            to='/listing-channels'
            hasBullet
            title={intl.formatMessage({id: 'MENU.ADD_MANAGE_CHANNEL'})}
          />
        </SidebarMenuItemWithSub>
        {
          !fetchedUserAccountData?.isStaffUser  && 
          <SidebarMenuItem
              to='/user-invite'
              icon='people'
              title={'User Management'}
            />
        }
          
        {/* {  !fetchedUserAccountData?.isStaffUser && ['SHOPIFY', 'WOOCOMMERCE','ECWID','COMMERCIUM','WIX' ].includes(fetchedUserAccountData?.source!) && (
            <SidebarMenuItem
              badge={ process.env['REACT_APP_ENV'] == 'production' ? true : false }
              to={ mainStoreData?.id ? `/pricing/${mainStoreData?.id}` : '/pricing' }
              icon='dollar'
              title={intl.formatMessage({id: 'MENU.PRICING'})}
            /> 
          )
        } */}

          {/* <SidebarMenuItem
            to='/order-acknowledges'
            icon='bucket'
            title={'Order Acknowledgement'}
          /> */}
          

        <SidebarMenuItem
          to=''
          // hasBullet
          icon='abstract-41'
          title={intl.formatMessage({id: 'SUPPORT'})}
            onClick={() => {
            window.location.href = `mailto:support@mycommercium.com`
          }}
        />
        <SidebarMenuItemForExternalLink
            to='https://commercium.tawk.help/'
            icon='book'
            title={'User Guide'}
        />

          {/* <SidebarMenuItem
            to={`/feature-request`}
            icon='send'
            title={'Feature Request'}
          /> */}
        
      </>
      : null
     }

     {
      fetchedUserAccountData?.user_type==='SUPERADMIN' ?
      <>
        <SidebarMenuItem
          to='/dashboard'
          icon='abstract-16'
          title={'Customers'}
        />
        <SidebarMenuItem
          to='/reports'
          icon='abstract-26'
          title={'Reports'}
        />
        <SidebarMenuItem
          to='/pricing-plans'
          icon='receipt-square'
          title={'Custom Plans'}
        />
      </>

      : null
     }


      {/* Product management section start */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Management</span>
        </div>
      </div> */}
      {/* <SidebarMenuItem
        to='/products-all'
        icon='abstract-16'
        title={intl.formatMessage({id: 'MENU.ALL_PRODUCT'})}
      /> */}

      {/* <SidebarMenuItem
        to='/inventory-all'
        icon='abstract-25'
        title={intl.formatMessage({id: 'MENU.INVENTORY'})}
      /> */}
      {/* Product management section end  */}

      {/* {clickedPlatform && <ProductsTable selectedPlatform={clickedPlatform} />} */}

      {/* Product management section end  */}

      {/* Custom navigation ends  */}

      {/* Theme navigation start  */}
      {/* <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      /> */}
      {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' /> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div> */}
      {/* <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}

      {/* Theme navigation ends  */}
    </>
  )
}

export {SidebarMenuMain}
